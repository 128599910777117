import React from 'react'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <Img alt="" fluid={article.heroImage.fluid} />
    <h3 className={styles.previewTitle}>
      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    </h3>
    <small>{article.publishDate}</small>
    <MDXRenderer>{article.description.childMdx.body}</MDXRenderer>
    {/*{article.tags.map(tag => (*/}
    {/*  <p className={styles.tag} key={tag}>*/}
    {/*    {tag}*/}
    {/*  </p>*/}
    {/*))}*/}
  </div>
)
