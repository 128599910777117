import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} pageName={'home'} >
        <div style={{ background: '#fff' }}>
          <Helmet title={'Home | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Custom Software Development</h1>
            <p>We specialize in:</p>
            <ul>
              <li>Creating green-field Node.js APIs</li>
              <li>Re-architecting existing Node.js monolithic applications to microservice-based APIs</li>
              <li>Crafting your testing and code health guidelines and leveling up your team and code to meet those</li>
              <li>Custom Node.js microservices that fit within your existing app structure</li>
              <li>Identifying your automated testing, acceptance and deployment strategy and helping you and your team implement the best build, testing and deployment strategy for your application</li>
            </ul>
            <p>We focus on your problems to deliver the right solution.</p>
            <p>Feel free to look at our <Link to={'/projects'}>projects</Link> to see what we might be able to do for you!</p>
            <p>Or schedule a free 30 minute call now.</p>
            <a href={"https://calendly.com/warnero/intro"} className={"btn btn-blue"}>Schedule Call!</a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPerson(filter: { contentful_id: { eq: "5698EQXlOUFK2Ur4uMGeV5" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "4IIHKBVaJKrKXjoP4CCgBz"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
